







































































































import Vue from 'vue';
import Auth from '@/libs/auth';
import { MemberGroupModel, MemberGroupPreviewModel } from '@/apis/models/MemberGroupModel';
import MemberGroupApi from '@/apis/MemberGroupApi';
import BackButton from '@/components/BackButton.vue';

export default Vue.extend({
  components: {
    BackButton,
  },
  data() {
    return {
      memberGroup: null as MemberGroupModel | null,
      memberGroupFormUrl: 'https://liff.line.me/1656074648-82kPY529#/MemberGroupForm',
      copyMessageDisplay: false,
      copyMessageTimeoutId: 0,
      // 幫助區
      // autoConfirm: false,
      // -----
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    memberGroupId(): string {
      return this.$store.state.currentMemberGroup.uuid;
    },
    currentMemberGroup(): MemberGroupPreviewModel {
      return this.$store.state.currentMemberGroup;
    },
    autoConfirm(): boolean {
      return this.$store.state.autoConfirm;
    },
    confirmedMembers(): number {
      let num = 0;
      if (this.memberGroup) {
        this.memberGroup.members.forEach((item) => {
          if (item.confirmed) {
            num += 1;
          }
        });
      }
      return num;
    },
    notConfirmedMembers(): number {
      let num = 0;
      if (this.memberGroup) {
        num = this.memberGroup.members.length - this.confirmedMembers;
      }
      return num;
    },
    editPermission(): boolean {
      return this.$store.state.editPermission;
    },
    memberGroupQueryUrl(): string {
      if (this.memberGroup) {
        return `${this.memberGroupFormUrl}?name=${this.memberGroup.metadata.name}&uniqueNum=${this.memberGroup.uniqueName.substr(-3)}`;
      }
      return '';
    },
  },
  async mounted() {
    Auth.loginCheck();
    this.$store.commit('updateLoading', true);
    try {
      this.memberGroup = await MemberGroupApi.getMemberGroup(this.memberGroupId);
    } finally {
      this.$store.commit('updateLoading', false);
    }
    // 幫助區
    // this.autoConfirm = this.currentMemberGroup.autoConfirm;
    // -----
  },
  methods: {
    toMemberGroupMaker() {
      this.$router.push('/manager/membergroup-maker');
    },
    copy() {
      const content = `您的班級名稱為"${this.memberGroup?.uniqueName}"，請於互動貼圖頻道填入您的班級名稱，或是點擊以下連結將自動加入班級。\n${this.memberGroupQueryUrl}`;
      this.$copyText(content).then(() => {
        this.copyMessageDisplay = true;
        this.copyMessageTimeoutId = setTimeout(this.clearCopyMessage, 3700);
        this.$notify({
          type: 'success',
          title: this.$t('已複製班級資訊').toString(),
          // text: content,
        });
      }, (e) => {
        console.error('複製失败', e.text);
      });
    },
    clearCopyMessage() {
      this.copyMessageDisplay = false;
    },
    clearCopyMessageTimeout() {
      this.clearCopyMessage();
      clearTimeout(this.copyMessageTimeoutId);
    },
  },
});
